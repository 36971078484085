import Ionicons from "@expo/vector-icons/Ionicons";
import { useEffect, useRef, useState } from "react";
import {
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/Notifications/NotificationComponent";

import i18n from "../../../locales/i18n";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import { sendForgotPwMail } from "../../functions/user/functions";
import { validateEmail } from "../../functions/user/functions/registry";
import getColorScheme from "../../hooks/useColorScheme";

export function ForgotPasswordScreen({ navigation }) {
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState("");

  const ref = useRef<any>(null);

  const colorScheme = getColorScheme();
  const styles = createStyles(colorScheme);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: 20,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <KeyboardAwareScrollView
        style={{ width: "100%" }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
      >
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            width: "100%",
            justifyContent: Platform.OS === "web" ? "center" : "flex-start",
            alignItems: "center",
          }}
        >
          <Text style={styles.greetingText}>
            Gib deine E-Mail ein, und wir senden dir einen Link zum Zurücksetzen
            deines Passworts.
          </Text>

          <TextInput
            placeholder={i18n.t("reset_password.placeholder")}
            textContentType="emailAddress"
            ref={ref}
            placeholderTextColor={Colors[colorScheme].placeholder}
            keyboardType="email-address"
            style={styles.textField}
            onChangeText={(t) => setMail(t)}
          />
        </View>
        <View
          style={{
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          <View
            style={{
              justifyContent: "center",
              width: "85%",
              maxWidth: Sizes.containerWidth,
              alignItems: "center",
              marginTop: 32,
            }}
          >
            <DefaultButton
              type={ButtonTypes.Primary}
              title={i18n.t("reset_password.reset")}
              enabled={validateEmail(mail)}
              loading={loading}
              icon={<Ionicons name="arrow-forward" size={24} />}
              action={async () => {
                setLoading(true);
                ref.current?.blur();
                const success = await sendForgotPwMail(mail);
                setLoading(false);
                if (success) {
                  showInlineNotification({
                    text: i18n.t("reset_password.success"),
                    type: InlineNotificationType.SUCCESS,
                    direction: InlineNotificationDirection.FROM_BOTTOM,
                  });
                } else {
                  showInlineNotification({
                    text: i18n.t("reset_password.failure"),
                    type: InlineNotificationType.ERROR,
                    direction: InlineNotificationDirection.FROM_BOTTOM,
                  });
                }
              }}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    welcomeText: {
      fontSize: 20,
      color: Colors[scheme].text,
      fontWeight: "700",
      textTransform: "uppercase",
    },
    greetingText: {
      marginTop: 24,
      fontSize: 13,
      marginHorizontal: 20,
      color: Colors[scheme].text,
      marginBottom: 24,
    },
    textField: {
      backgroundColor: Colors[scheme].foreground,
      color: Colors[scheme].text,
      borderRadius: 12,
      width: "85%",
      height: 50,
      padding: 12,
      marginBottom: 12,
      maxWidth: Sizes.containerWidth,
    },
  });

  return styles;
};
