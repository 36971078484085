import * as FileSystem from "expo-file-system";

const OFFLINE_DATA_PATH = `${FileSystem.documentDirectory}offlineData.json`;

export const saveDataToFile = async (data) => {
  try {
    const jsonData = JSON.stringify(data);
    //console.log("Data stringified:", jsonData);
    await FileSystem.writeAsStringAsync(OFFLINE_DATA_PATH, jsonData, {
      encoding: FileSystem.EncodingType.UTF8,
    });
  } catch (error) {
    console.error("Error saving data to file:", error);
  }
};

export const loadDataFromFile = async () => {
  try {
    const fileExists = await FileSystem.getInfoAsync(OFFLINE_DATA_PATH);

    if (!fileExists.exists) {
      return null;
    }

    const jsonData = await FileSystem.readAsStringAsync(OFFLINE_DATA_PATH, {
      encoding: FileSystem.EncodingType.UTF8,
    });
    //console.log("Data read from file:", jsonData);

    const parsedData = JSON.parse(jsonData);
    //console.log("Data parsed:", parsedData);
    return parsedData;
  } catch (error) {
    return null;
  }
};
