// PrimeSection.tsx
import React, { useState } from "react";
import { ActivityIndicator, View } from "react-native";

import { Sizes } from "../../constants/static-sizes";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import LargePartnerCard from "./LargePartnerCard"; // Update path as needed
import { useData } from "./PartnerDataProvider";
import InViewPort from "../InViewComponent/InViewComponent";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";

export const MIN_IMPRESSION_DURATION = 2000; // Minimum time (ms) for an impression

const PrimeSection: React.FC = () => {
  const { data, loading } = useData();

  const today = new Date();
  const dayOfMonth = today.getDate();
  const primeTiles = data[dayOfMonth]?.primeTile || [];

  const [hasLoggedImpression, setHasLoggedImpression] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  if (loading) {
    return <ActivityIndicator size="small" color="gray" />;
  }

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible && !hasLoggedImpression) {
      if (!timer) {
        const newTimer = setTimeout(() => {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "prime_section_impression",
            "PrimeSection",
            "Impression",
            {}
          );
          setHasLoggedImpression(true);
          setTimer(null);
        }, MIN_IMPRESSION_DURATION);
        setTimer(newTimer);
      }
    } else {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
    }
  };

  if (primeTiles.length === 0) return null;

  return (
    <InViewPort onChange={(isVisible) => handleVisibilityChange(isVisible)}>
      <View
        style={{
          borderBottomColor: "#bbb",
          borderBottomWidth: 0.3,
          paddingBottom: 32,
        }}
      >
        <View
          style={{
            marginHorizontal: -Sizes.defaultContainerPadding,
            gap: 20,
            alignItems: "center",
          }}
        >
          <CarouselComponent
            data={primeTiles}
            autoplay
            autoplayInterval={8000}
            renderItem={({ item }) => <LargePartnerCard item={item} />}
          />
        </View>
      </View>
    </InViewPort>
  );
};

export default PrimeSection;
