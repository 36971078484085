import { Ionicons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import configureAnimations from "../../functions/configure-animations";
import { getValueFromInteractions } from "../../functions/user-interaction-handler";
import CustomModal from "./CustomModal";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";

// (Paste in all the helpers above: streakData, formatDate, getCurrentWeekDates,
//  getLast4WeeksAsArray, getDayAppearance, findAllConsecutiveStreakSegments, etc.)

interface StreakModalProps {
  open: boolean;
  onClose: () => void;
}

/**
 * Returns array of 7 dates for the current (Mon–Sun) "collapsed" week.
 */
function getCurrentWeekDates(): string[] {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0=Sun,1=Mon,...6=Sat
  const daysSinceMonday = (dayOfWeek + 6) % 7;
  const monday = new Date(today);
  monday.setDate(today.getDate() - daysSinceMonday);

  const week: string[] = [];
  for (let i = 0; i < 7; i++) {
    const d = new Date(monday);
    d.setDate(monday.getDate() + i);
    week.push(__formatDate(d));
  }
  return week;
}

/**
 * Returns the last 4 weeks (28 days) as ONE continuous array:
 *   [day0, day1, ..., day27], ascending in date.
 * This includes the current week + 3 preceding weeks.
 */
function getLast4WeeksAsArray(): string[] {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysSinceMonday = (dayOfWeek + 6) % 7;
  const currentMonday = new Date(today);
  currentMonday.setDate(today.getDate() - daysSinceMonday);

  // We'll collect 28 days, from Monday of 3 weeks ago up to Sunday of the current week
  // (or vice versa, but we typically do oldest first → newest).
  // e.g. "week0" is 3 weeks ago, "week3" is the current week
  const allDays: string[] = [];

  // We want 4 weeks total = 4 * 7 = 28 days.
  // If we want the oldest day first, we start from Monday (3 weeks ago).
  // i=0 => 3 weeks ago, i=3 => this week
  for (let w = 3; w >= 0; w--) {
    const mondayOfWeek = new Date(currentMonday);
    mondayOfWeek.setDate(mondayOfWeek.getDate() - 7 * w);

    for (let i = 0; i < 7; i++) {
      const d = new Date(mondayOfWeek);
      d.setDate(mondayOfWeek.getDate() + i);
      allDays.push(__formatDate(d));
    }
  }

  // Now allDays[0..6] = earliest week, allDays[21..27] = current week
  // So it’s in ascending order from oldest to newest. Great!
  return allDays;
}

/**
 * Returns an object for each date with ( icon, color, isStreak )
 * depending on whether it's "today", past with streak, etc.
 */
function getDayAppearance(dateStr: string, todayStr: string, streakData) {
  const dObj = new Date(dateStr);
  const tObj = new Date(todayStr);
  dObj.setHours(0, 0, 0, 0);
  tObj.setHours(0, 0, 0, 0);

  if (dObj.getTime() === tObj.getTime()) {
    // "today"
    return {
      icon: require("../../../assets/images/streaks/highlighted.png"),
      color: "#FE9506",
      isStreak: false, // "today" is its own state
      isToday: true,
    };
  } else if (dObj < tObj) {
    // Past => check streak
    const hadStreak = streakData[dateStr] === true;
    if (hadStreak) {
      return {
        icon: require("../../../assets/images/streaks/completed.png"),
        color: "#FFFFFF",
        isStreak: true,
      };
    } else {
      return {
        icon: require("../../../assets/images/streaks/past_gone.png"),
        color: "#FFFFFF50",
        isStreak: false,
      };
    }
  } else {
    // Future
    return {
      icon: require("../../../assets/images/streaks/future.png"),
      color: "#FFFFFF",
      isStreak: false,
    };
  }
}

/**
 * For each 7-day row, we see which [segmentStart, segmentEnd] ranges
 * overlap this row’s [rowStart, rowEnd].
 * Then we clamp the segment to that overlap and draw that partial bar.
 */
function renderExpandedRow(
  allDays: string[],
  rowIndex: number,
  segments: Array<[number, number]>,
  todayStr: string,
  streakData
) {
  const rowStart = rowIndex * 7;
  const rowEnd = rowStart + 6; // inclusive

  // Subarray of days for this row
  const rowDays = allDays.slice(rowStart, rowEnd + 1);

  // Calculate "yesterday" for comparison
  const yesterday = new Date(todayStr);
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayStr = __formatDate(yesterday);

  return (
    <View
      key={`row-${rowIndex}`}
      style={{
        position: "relative",
        flexDirection: "row",
        width: 7 * 40,
        justifyContent: "space-between",
        marginBottom: 20,
      }}
    >
      {/* Render partial highlight bars for any overlapping segments */}
      {segments.map(([segStart, segEnd], i) => {
        if (segEnd < rowStart || segStart > rowEnd) {
          // Skip if segment doesn't overlap this row
          return null;
        }

        // Overlap from max(rowStart, segStart) to min(rowEnd, segEnd)
        const localStart = Math.max(rowStart, segStart) - rowStart;
        const localEnd = Math.min(rowEnd, segEnd) - rowStart;

        // Determine if the segment is connected to yesterday
        const isConnectedToYesterday =
          segEnd === allDays.indexOf(yesterdayStr) ||
          (segStart <= allDays.indexOf(yesterdayStr) &&
            segEnd >= allDays.indexOf(yesterdayStr));

        // Border radius adjustments
        const isFirstInRow = segStart >= rowStart && segStart <= rowEnd;
        const isLastInRow = segEnd >= rowStart && segEnd <= rowEnd;

        const borderRadiusStyle = {
          borderTopLeftRadius: isFirstInRow ? 16 : 0,
          borderBottomLeftRadius: isFirstInRow ? 16 : 0,
          borderTopRightRadius: isLastInRow ? 16 : 0,
          borderBottomRightRadius: isLastInRow ? 16 : 0,
        };

        // Background color: connected streaks vs past streaks
        // Calculate streak length for this segment
        let streakLength = segEnd - segStart + 1;
        if (isConnectedToYesterday) {
          streakLength = getLastConsecutiveStreakIfYesterday(streakData);
        }

        // Determine the background color based on streak length
        let backgroundColor = "rgba(175, 175, 175, 0.30)"; // default: gray for past streaks not connected
        if (isConnectedToYesterday) {
          if (streakLength >= 1 && streakLength <= 3) {
            backgroundColor = "rgba(68, 134, 201, 0.50)"; // blue for 1-3 days
          } else if (streakLength > 3 && streakLength <= 30) {
            backgroundColor = "rgba(253, 202, 0, 0.50)"; // yellow for up to 1 month
          } else if (streakLength > 30 && streakLength <= 90) {
            backgroundColor = "rgba(255, 147, 0, 0.50)"; // orange for up to 3 months
          } else if (streakLength > 90) {
            backgroundColor = "rgba(255, 25, 25, 0.50)"; // red for more than 3 months
          }
        }

        return (
          <View
            key={`seg-${segStart}-${segEnd}-${i}`}
            style={{
              position: "absolute",
              left: localStart * 40,
              bottom: 0,
              width: 40 * (localEnd - localStart + 1),
              height: 32,
              backgroundColor,
              ...borderRadiusStyle,
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 4 },
              shadowOpacity: 0.25,
              shadowRadius: 4,
              elevation: 4,
              zIndex: -1,
            }}
          />
        );
      })}

      {/* Render the icons */}
      {rowDays.map((dateStr, i) => {
        const { isStreak, isToday } = getDayAppearance(
          dateStr,
          todayStr,
          streakData
        );

        // Determine if the streak is connected to yesterday
        const isConnectedToYesterday = segments.some(
          ([segStart, segEnd]) =>
            allDays.indexOf(dateStr) >= segStart &&
            allDays.indexOf(dateStr) <= segEnd &&
            (segEnd === allDays.indexOf(yesterdayStr) ||
              (segStart <= allDays.indexOf(yesterdayStr) &&
                segEnd >= allDays.indexOf(yesterdayStr)))
        );

        let displayIcon;
        if (isToday) {
          displayIcon = require("../../../assets/images/streaks/highlighted.png");
        } else if (isStreak && !isConnectedToYesterday) {
          displayIcon = require("../../../assets/images/streaks/past.png");
        } else if (isStreak) {
          displayIcon = require("../../../assets/images/streaks/completed.png");
        } else if (new Date(dateStr) < new Date(todayStr)) {
          displayIcon = require("../../../assets/images/streaks/past_gone.png");
        } else {
          displayIcon = require("../../../assets/images/streaks/future.png");
        }

        return (
          <View
            key={dateStr}
            style={{
              width: 40,
              alignItems: "center",
              paddingBottom: 5,
            }}
          >
            <Image
              source={displayIcon}
              style={{
                width: 23,
                height: 23,
                resizeMode: "contain",
              }}
            />
          </View>
        );
      })}
    </View>
  );
}

/**
 * Find all consecutive streak segments (≥ 2 days) across
 * the entire 28-day array. We'll store them as [startIndex, endIndex].
 */
function findAllConsecutiveStreakSegments(
  allDays: string[],
  todayStr: string,
  streakData
) {
  const segments: Array<[number, number]> = [];
  let start = -1;

  for (let i = 0; i < allDays.length; i++) {
    const { isStreak } = getDayAppearance(allDays[i], todayStr, streakData);

    if (isStreak) {
      // start a new segment if not already in one
      if (start === -1) {
        start = i;
      }
    } else {
      // we just hit a non-streak day
      if (start !== -1) {
        const length = i - start; // how many consecutive days
        if (length >= 1) {
          segments.push([start, i - 1]);
        }
        start = -1;
      }
    }
  }

  // If we ended in a streak segment
  if (start !== -1) {
    const length = allDays.length - start;
    if (length >= 1) {
      segments.push([start, allDays.length - 1]);
    }
  }

  return segments;
}

const StreakModal: React.FC<StreakModalProps> = ({ open, onClose }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // "today" as YYYY-MM-DD
  const now = new Date();
  const todayStr = __formatDate(now);

  const [streakData, setStreakData] = useState<Record<string, boolean>>({});

  // Collapsed → single week
  const currentWeek = getCurrentWeekDates();

  useEffect(() => {
    const streakKey = "streak_data";

    // Get existing streak data from interactions
    let streakData = getValueFromInteractions(streakKey) || "{}";
    while (typeof streakData === "string") {
      streakData = JSON.parse(streakData);
    }

    setStreakData(streakData);

    if (open) {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "streak_modal_opened",
        "StreakModal",
        "opened",
        {}
      );
    }
  }, [open]);

  // Expanded → entire 4-week array (28 days) + consecutive streak segments
  const allDays = getLast4WeeksAsArray(); // length 28
  const segments = findAllConsecutiveStreakSegments(
    allDays,
    todayStr,
    streakData
  );

  // Collapsed row: same single highlight bar approach
  function renderCollapsedWeek() {
    // Use the current week as the "allDays" array for the single row
    const allDays = currentWeek;

    // Extract segments for the current week
    const segments: Array<[number, number]> = [];
    let streakStart: number | null = null;

    for (let i = 0; i < currentWeek.length; i++) {
      const { isStreak } = getDayAppearance(
        currentWeek[i],
        todayStr,
        streakData
      );

      if (isStreak) {
        if (streakStart === null) {
          streakStart = i; // Start a new streak
        }
      } else {
        if (streakStart !== null) {
          segments.push([streakStart, i - 1]); // End the streak
          streakStart = null;
        }
      }
    }

    // If there's an ongoing streak at the end of the week, push it
    if (streakStart !== null) {
      segments.push([streakStart, currentWeek.length - 1]);
    }

    // Day names for the current week
    const dayNames = currentWeek.map((dateStr) => {
      const dayIndex = new Date(dateStr).getDay();
      const dayName = ["SO", "MO", "DI", "MI", "DO", "FR", "SA"][dayIndex];
      return {
        dayName,
        isToday: dateStr === todayStr,
      };
    });

    return (
      <View
        style={{
          marginBottom: -20,
        }}
      >
        {/* Render weekdays above the row */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          {dayNames.map((day, index) => (
            <Text
              key={index}
              style={{
                width: 40,
                textAlign: "center",
                color: day.isToday ? "#FE9506" : "#FFFFFF", // Highlight current day
                fontSize: 14,
                fontWeight: day.isToday ? "900" : "700", // Bold for current day
              }}
            >
              {day.dayName}
            </Text>
          ))}
        </View>

        {/* Call renderExpandedRow for the single week */}
        {renderExpandedRow(allDays, 0, segments, todayStr, streakData)}
      </View>
    );
  }

  // Renders the expanded view → 4 rows, each row covering 7 days from the big array
  function renderExpandedView() {
    // We have 4 rows: row 0 => indices 0..6, row 1 => 7..13, row 2 => 14..20, row 3 => 21..27
    const rows: any = [];
    for (let rowIdx = 0; rowIdx < 4; rowIdx++) {
      rows.push(
        renderExpandedRow(allDays, rowIdx, segments, todayStr, streakData)
      );
    }

    return <View style={{ marginBottom: -20 }}>{rows}</View>;
  }

  return (
    <CustomModal
      dark
      style={{
        backgroundColor: "#1A1A1A",
        borderRadius: 20,
      }}
      open={open}
      onClose={onClose}
      showClose={true}
    >
      <View
        style={{
          width: "100%",
          alignItems: "center",
          paddingVertical: 20,
          paddingHorizontal: 16,
        }}
      >
        <CurrentStreakView
          days={getLastConsecutiveStreakIfYesterday(streakData)}
        />

        {/* Collapsed view: single row with day names */}
        {!isExpanded && renderCollapsedWeek()}

        {/* Expanded view: 4 rows, no day names, highlight bars can cross row boundaries */}
        {isExpanded && renderExpandedView()}

        {/* Toggle button */}
        <TouchableOpacity
          onPress={() => {
            configureAnimations();
            setIsExpanded(!isExpanded);
          }}
          style={{
            marginTop: 20,
            padding: 5,
          }}
        >
          <Ionicons
            name={isExpanded ? "chevron-up" : "chevron-down"}
            size={24}
            color="#fff"
          />
        </TouchableOpacity>
      </View>
    </CustomModal>
  );
};

export default StreakModal;

export function getLastConsecutiveStreakIfYesterday(
  streakData: Record<string, boolean>
): number {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const yesterdayStr = __formatDate(yesterday); // Convert `yesterday` to "YYYY-MM-DD" format.

  // If yesterday is not part of the streak, return 0
  if (!streakData[yesterdayStr]) {
    return 1;
  }

  // Count the consecutive streak, starting from yesterday
  let streakCount = 0;
  const currentDate = new Date(yesterday);

  while (true) {
    const currentDateStr = __formatDate(currentDate);

    if (streakData[currentDateStr]) {
      streakCount++;
      // Move to the previous day
      currentDate.setDate(currentDate.getDate() - 1);
    } else {
      break; // Streak ends if the current day is not a streak day
    }
  }

  return streakCount + 1;
}

// Utility function to format a Date object to "YYYY-MM-DD"
function __formatDate(date: Date): string {
  return date.toISOString().split("T")[0];
}

const CurrentStreakView: React.FC<{ days: number }> = ({ days }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>Aktueller Streak</Text>
      <View style={styles.streakBox}>
        <Text style={styles.streakText}>
          {days} {days === 1 ? "Tag" : "Tage"}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 10,
    gap: 20,
    marginBottom: 16,
  },
  label: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  streakBox: {
    borderRadius: 6.452,
    backgroundColor: "rgba(0, 0, 0, 0.50)",
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  streakText: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    color: "#FE9506",
  },
});
