// StreakIndicator.tsx
import AsyncStorage from "@react-native-async-storage/async-storage";
import LottieView from "lottie-react-native";
import React, { useEffect, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { getValueFromInteractions } from "../../../functions/user-interaction-handler";
import StreakModal, {
  getLastConsecutiveStreakIfYesterday,
} from "../../modals/StreakModal";

// If you already have these or similar utilities, adjust imports accordingly:

/**
 * StreakIndicator:
 * 1) Loads "old" streak days (cached) immediately from AsyncStorage.
 * 2) After 1.5s, loads fresh data from userInteractionHandler.
 * 3) If old=0 -> new≥1, shows "activation" Lottie animation once.
 * 4) If old>1 -> new=0, shows "deactivation" Lottie animation once.
 * 5) Otherwise, displays an "active" icon if new≥1, or "inactive" if new=0.
 */
const StreakIndicator: React.FC = () => {
  // Old streak days: from local storage (yesterday’s version)
  const [oldStreakDays, setOldStreakDays] = useState<number>(0);

  // Current streak days: loaded after 1.5s from user interactions
  const [currentStreakDays, setCurrentStreakDays] = useState<number>(0);
  const [isCurrentStreakUpdated, setIsCurrentStreakUpdated] = useState(false);

  const [showStreakModal, setShowStreakModal] = useState(false);

  // Animation state
  const [showLottie, setShowLottie] = useState<boolean>(false);
  const [whichAnimation, setWhichAnimation] = useState<
    "flame" | "flame_off" | null
  >(null);

  // 1) Immediately load old/cached streak data
  useEffect(() => {
    (async () => {
      try {
        const localStreakDataString = await AsyncStorage.getItem(
          "streak_data_local"
        );
        if (localStreakDataString) {
          const localStreakDataObj = JSON.parse(localStreakDataString);
          const oldCount =
            getLastConsecutiveStreakIfYesterday(localStreakDataObj);
          setOldStreakDays(oldCount);
        }
      } catch (err) {
        console.warn("Error reading local streak data:", err);
      }
    })();
  }, []);

  // 2) After 1.5s, load fresh streak data from user interactions
  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        const streakKey = "streak_data";
        let data = getValueFromInteractions(streakKey) || "{}";
        while (typeof data === "string") {
          data = JSON.parse(data);
        }

        // Update local storage for next time
        await AsyncStorage.setItem("streak_data_local", JSON.stringify(data));

        // This is our "new" streak
        const newCount = getLastConsecutiveStreakIfYesterday(data);
        setCurrentStreakDays(newCount);
        setIsCurrentStreakUpdated(true);
      } catch (err) {
        console.warn("Error reading fresh streak data:", err);
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  // 3) Compare old vs. new streak days — decide if we show an animation
  useEffect(() => {
    if (!isCurrentStreakUpdated) {
      setShowLottie(false);
      setCurrentStreakDays(oldStreakDays);

      return;
    }

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "streak_indicator",
      "streak_indicator",
      {
        currentStreakDays,
      }
    );
    // Activation: old=0, new≥1
    if (oldStreakDays === 0 && currentStreakDays >= 1) {
      setShowLottie(true);
      setWhichAnimation("flame");
    }
    // Deactivation: old>1, new=0
    else if (oldStreakDays > 1 && currentStreakDays === 0) {
      setShowLottie(true);
      setWhichAnimation("flame_off");
    }
    // No special transition => just static icon
    else {
      setShowLottie(false);
      setWhichAnimation(null);
    }
  }, [oldStreakDays, currentStreakDays, isCurrentStreakUpdated]);

  // 4) Render either Lottie animation (once) or the static streak icon

  if (currentStreakDays === 0 && oldStreakDays === 0 && !isCurrentStreakUpdated)
    return null;

  return (
    <>
      <StreakModal
        open={showStreakModal}
        onClose={() => setShowStreakModal(false)}
      />
      <TouchableOpacity
        onPress={() => setShowStreakModal(true)}
        activeOpacity={0.7}
      >
        <View>
          {/* Activation animation */}
          {showLottie && whichAnimation === "flame" && (
            <LottieView
              source={require("../../../../assets/animations/flame.json")}
              autoPlay
              loop={false}
              //onAnimationFinish={() => setShowLottie(false)}
              style={{ width: 72, height: 72, marginRight: -12 }}
            />
          )}

          {/* Deactivation animation */}
          {showLottie && whichAnimation === "flame_off" && (
            <LottieView
              source={require("../../../../assets/animations/flame_off.json")}
              autoPlay
              loop={false}
              //onAnimationFinish={() => setShowLottie(false)}
              style={{ width: 60, height: 60, marginRight: -6 }}
            />
          )}

          {/* Fallback to static icons if no animation playing */}
          {!showLottie && (
            <View>
              {currentStreakDays >= 2 ? (
                <Image
                  source={require("../../../../assets/images/streaks/active.png")}
                  style={{ width: 48, height: 48 }}
                />
              ) : (
                <Image
                  source={require("../../../../assets/images/streaks/inactive.png")}
                  style={{ width: 48, height: 48 }}
                />
              )}
            </View>
          )}
        </View>
      </TouchableOpacity>
    </>
  );
};

export default StreakIndicator;
