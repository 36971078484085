import React, { useState } from "react";
import { ActivityIndicator, Dimensions, View } from "react-native";

import { Sizes } from "../../constants/static-sizes";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import { useData } from "./PartnerDataProvider";
import SearchCard from "./SearchCard";
import InViewPort from "../InViewComponent/InViewComponent";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { MIN_IMPRESSION_DURATION } from "./PrimeSection";

const { width } = Dimensions.get("window");

const SearchSection: React.FC = () => {
  const { data, loading } = useData();

  const today = new Date();
  const dayOfMonth = today.getDate();
  const searchTiles = data[dayOfMonth]?.banner || [];

  const [hasLoggedImpression, setHasLoggedImpression] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  if (loading) {
    return <ActivityIndicator size="small" color="gray" />;
  }

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible && !hasLoggedImpression) {
      if (!timer) {
        const newTimer = setTimeout(() => {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "search_section_impression",
            "SearchSection",
            "Impression",
            {}
          );
          setHasLoggedImpression(true);
          setTimer(null);
        }, MIN_IMPRESSION_DURATION);
        setTimer(newTimer);
      }
    } else {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
    }
  };

  if (searchTiles.length === 0) return null;

  return (
    <InViewPort onChange={(isVisible) => handleVisibilityChange(isVisible)}>
      <View
        style={{
          gap: 20,
          alignItems: "center",
          paddingBottom: 24,
          marginBottom: 24,

          marginHorizontal: -Sizes.defaultContainerPadding,
        }}
      >
        <CarouselComponent
          data={searchTiles}
          renderItem={({ item }) => <SearchCard item={item} />}
          sliderWidth={width}
          itemWidth={width * 0.8}
          autoplay
          autoplayDelay={2000}
          autoplayInterval={8000}
          loop
          vertical={false}
          containerCustomStyle={{ overflow: "visible" }}
        />
        {/* {searchTiles.length > 1 && (
        <Dots current={currentIndex} amount={searchTiles.length - 1} />
      )} */}

        <View
          style={{
            alignSelf: "stretch",
            marginHorizontal: Sizes.defaultContainerPadding,
            borderBottomColor: "#bbb",
            borderBottomWidth: 0.3,
          }}
        />
      </View>
    </InViewPort>
  );
};

export default SearchSection;
