import AsyncStorage from "@react-native-async-storage/async-storage";
import * as StoreReview from "expo-store-review";
import * as sha512 from "js-sha512";
import _ from "lodash";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import apiCall, { loginCall } from "../../api/api-call";
import { parser } from "../../api/content/data-helpers";

import { Platform } from "react-native";
import {
  CASE_ID,
  NOTE_ID,
  SHORTCUT_ID,
  handleComparisonTempAndOnline,
} from "../handle-offline-temp-storage";
import { store } from "../store";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../user-interaction-handler";
import {
  addBookmarkToUser,
  addToUserPageHistory,
  changePassword,
  deleteNoteFromUser,
  deletePatientFromUser,
  removeBookmarkFromUser,
  setCurrentUser,
  updateNoteInUser,
  updatePatientInUser,
} from "./actions";
import { Note, User } from "./types";
import { getIsOffline } from "../../components/search-tab/SearchResults";
import {
  addToQueue,
  synchronizeInteraction,
} from "../sync-offline-interaction-handler";

export async function setUser(userData) {
  if (!userData.result_page_history?.history)
    userData.result_page_history = { history: [], total_results: 0 };
  if (!userData.interaction_records) userData.interaction_records = [];
  if (userData.user_type === "doc") userData.user_type = "doctor";
  delete userData.team;

  const offlineUserData: User = JSON.parse(
    (await AsyncStorage.getItem("user")) ?? "null"
  );

  if (!offlineUserData || offlineUserData.user_id !== userData.user_id) {
    store.dispatch(setCurrentUser(userData));
    await updateOfflineUser(userData);
    return { valid: true, userData };
  }

  if (Platform.OS !== "web") {
    const comparisonPromises = [
      handleComparisonTempAndOnline(
        offlineUserData?.cases,
        userData?.cases,
        CASE_ID,
        userData.user_id,
        _.isEqual
      ),
      handleComparisonTempAndOnline(
        offlineUserData?.bookmarks,
        userData?.bookmarks,
        SHORTCUT_ID,
        userData.user_id,
        (item1, item2) => item1.result_page_id === item2.result_page_id
      ),
      handleComparisonTempAndOnline(
        offlineUserData?.notes,
        userData?.notes,
        NOTE_ID,
        userData.user_id,
        _.isEqual
      ),
    ];

    const results = await Promise.all(comparisonPromises);
    // Proceed if all comparisons return true
    if (results.every((result) => result === true)) {
      store.dispatch(setCurrentUser(userData));
      await updateOfflineUser(userData);
    } else {
      await AsyncStorage.setItem("user", "null");
      await userLogin(userData.email, userData.password);
    }
  } else {
    store.dispatch(setCurrentUser(userData));
    await updateOfflineUser(userData);
  }

  return { valid: true, userData };
}

export async function userLogin(user: string, password: string) {
  try {
    const response = await loginCall(password, user.toLowerCase().trim());

    if (!response) return { valid: false, userData: null };
    if (response.status === 403) {
      const userData = {
        email: user,
        password,
        status: "pending",
      };

      store.dispatch(setCurrentUser(userData));
      await updateOfflineUser(userData as any);
      return { valid: true, userData: userData };
    } else if (response.status === 401) {
      return { valid: false, userData: null };
    }
    const userData = response.userData as User;

    if (userData === undefined) return { valid: false, userData: null };

    return setUser(userData);
  } catch (e) {
    return { valid: false, userData: null };
  }
}

export async function showRatingIfApplicable() {
  const currentUser = store.getState().userReducer.user;

  if (currentUser.has_rated) return false;

  if (
    (await StoreReview.hasAction()) &&
    (await StoreReview.isAvailableAsync())
  ) {
    if (currentUser.has_rated === undefined || currentUser.has_rated === true) {
      let counter = getValueFromInteractions("rating_counter");
      if (!counter) counter = "0";
      if (counter !== "20") {
        await addValueToInteractions(
          "rating_counter",
          parseInt(counter) + 1 + ""
        );
        return;
      } else {
        await addValueToInteractions("rating_counter", 0 + "");
      }
    }

    return true;
  }
  return false;
}

/**
 * Adds a result page to the user's history.
 *
 * @param id - The ID of the result page to add to the history.
 * @returns A Promise that resolves when the result page is successfully added to the history.
 */
export async function addToUserHistory(id) {
  const currentUser = store.getState().userReducer.user;
  await synchronizeInteraction({
    action: "addToUserHistory",
    payload: { id, user_email: currentUser.email },
    dispatch: () =>
      store.dispatch(
        addToUserPageHistory({ result_page_id: id, timestamp: new Date() })
      ),
  });
}

export async function sendForgotPwMail(mail: string) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "forgot_password_requested",
    "ForgotPasswordScreen",
    mail
  );
  try {
    const response = await apiCall("/backend/request_new_password", {
      user: mail.toLowerCase(),
    });
    return response.status === 200;
  } catch {
    return false;
  }
}

export async function setLogged(
  logged: boolean,
  email: string,
  password: string,
  auth: string
) {
  try {
    await AsyncStorage.setItem("isLogged", logged.toString());
    await AsyncStorage.setItem("email", email);
    await AsyncStorage.setItem("password", password);
    await AsyncStorage.setItem("auth", auth);
    await AsyncStorage.setItem("did_ask_push", "0");
    return true;
  } catch {
    return false;
  }
}

export async function getUserDataForLoggedIn() {
  const email = await AsyncStorage.getItem("email");
  const password = await AsyncStorage.getItem("password");

  if (email && password) {
    try {
      const loginSuccess = await userLogin(email, password);

      return loginSuccess.valid;
    } catch {
      return false;
    }
  }
  return false;
}

export async function updateOfflineUser(currentUser: User) {
  try {
    await AsyncStorage.setItem("user", JSON.stringify(currentUser));
    return true;
  } catch {
    return false;
  }
}

export async function getOfflineUser() {
  const offlineUserString = await AsyncStorage.getItem("user");
  if (offlineUserString === null) return false;

  const offlineUser = JSON.parse(offlineUserString);
  if (offlineUser?.role) offlineUser.user_type = offlineUser.role;
  store.dispatch(setCurrentUser(offlineUser));

  return true;
}

export async function isLogged() {
  const l = await AsyncStorage.getItem("isLogged");

  if (l !== null) {
    if (l === "true") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function changePwAndSendMail(oldPassword, newPassword) {
  const hashedPassword = sha512.sha512(newPassword);

  try {
    const response = await apiCall(
      "/api/v3/users/change-password",
      {
        old_password: oldPassword,
        new_password: hashedPassword,
      },
      false,
      false,
      "PATCH"
    );
    store.dispatch(changePassword({ password: hashedPassword }));
    await updateOfflineUser(store.getState().userReducer.user as any);

    return response.status === 200;
  } catch {
    return false;
  }
}

export async function deletePatient(id: string) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "deleted_patient",
    "Patient",
    "removed",
    id
  );

  synchronizeInteraction({
    action: "removePatient",
    payload: { id },
    dispatch: () => store.dispatch(deletePatientFromUser(id)),
  });
}

export async function addPatient(patient) {
  console.log("PATIENT", patient);
  const response = await synchronizeInteraction({
    action: "addPatient",
    payload: patient,
    dispatch: () => store.dispatch(updatePatientInUser(patient)),
  });
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "added_patient",
    "Patient",
    "added",
    patient.id
  );

  return patient.id;
}

export async function addPageToPatientCreate(patient, pageId, data) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "added_page_to_patient",
    "Patient",
    "added",
    pageId
  );

  const updatedPatient = {
    ...patient,
    data: [...(patient.data ?? []), { result_page_id: pageId, data }],
  };

  await synchronizeInteraction({
    action: "addPageToPatientCreate",
    payload: { patient, pageId, data },
    dispatch: () => store.dispatch(updatePatientInUser(updatedPatient)),
  });
}

export async function deletePageFromPatient(patient, pageId, data) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "added_page_to_patient",
    "Patient",
    "added",
    pageId
  );

  const updatedPatient = {
    ...patient,
    data: patient.data.filter((item) => item.result_page_id !== pageId),
  };

  await synchronizeInteraction({
    action: "deletePageFromPatient",
    payload: { data },
    dispatch: () => store.dispatch(updatePatientInUser(updatedPatient)),
  });
}

export async function addToPagePatientUpdate(patient, pageId, data) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "added_page_to_patient",
    "Patient",
    "added",
    pageId
  );

  const updatedPatient = {
    ...patient,
    data: [...patient.data, { result_page_id: pageId, data }],
  };

  await synchronizeInteraction({
    action: "addPageToPatientUpdate",
    payload: { patient, pageId, data },
    dispatch: () => store.dispatch(updatePatientInUser(updatedPatient)),
  });
}

export async function updatePatient(patient) {
  console.log(patient);
  let currentUser = store.getState().userReducer.user as any;
  await synchronizeInteraction({
    action: "updatePatient",
    payload: patient,
    dispatch: () => store.dispatch(updatePatientInUser(patient)),
  });
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "patient_updated",
    "PatientUpdated"
  );
}

export async function removeBookmark(id: string) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "removed_favorite",
    "Shortcut",
    "removed",
    id
  );
  let currentUser = store.getState().userReducer.user as any;
  await synchronizeInteraction({
    action: "removeBookmark",
    payload: { id, user_id: currentUser.user_id },
    dispatch: () => store.dispatch(removeBookmarkFromUser(id)),
  });
}

export async function addBookmark(id: string) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "added_favorite",
    "Shortcut",
    "added",
    id
  );
  let currentUser = store.getState().userReducer.user as any;
  await synchronizeInteraction({
    action: "addBookmark",
    payload: { id, user_id: currentUser.user_id },
    dispatch: () =>
      store.dispatch(
        addBookmarkToUser({
          result_page_id: id,
          created_at: new Date().toISOString(),
          score: 0,
          visit_count: 1,
        })
      ),
  });
}

export async function updateNote(note: Note) {
  if (!note?.note && note?.note !== "") return;
  note.note = note.note.replace(/div/g, "p");
  note.note = parser(note.note);
  let currentUser = store.getState().userReducer.user as any;
  await synchronizeInteraction({
    action: "updateNote",
    payload: { note, user_id: currentUser.user_id },
    dispatch: () => store.dispatch(updateNoteInUser(note)),
  });
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "note_updated",
    "NoteUpdated",
    note.note
  );
}

export async function updateUserPropertyCall(currentUser, property, value) {
  try {
    await apiCall(
      "/api/v2/users/" + currentUser.user_id + "/properties",
      { [property]: value },
      false,
      false,
      "PUT"
    );

    return true;
  } catch {
    return false;
  }
}

export async function deleteNote(noteId) {
  let currentUser = store.getState().userReducer.user as any;
  await synchronizeInteraction({
    action: "deleteNote",
    payload: { noteId, user_id: currentUser.user_id },
    dispatch: () => store.dispatch(deleteNoteFromUser(noteId)),
  });

  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "note_deleted",
    "NoteUpdated"
  );
}
