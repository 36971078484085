import { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import I18n from "../../locales/i18n";
import DefaultBackHeader from "../components/header/DefaultBackHeaderComponent";
import SearchResults from "../components/search-tab/SearchResults";
import { Sizes } from "../constants/static-sizes";
import { SearchBar } from "./tab-navigator-screens/components/SearchBar";
import staticColors from "../constants/static-colors";

export default function GlobalSearchScreen({ route }) {
  const [selected, setSelected] = useState(true);

  const [filters, setFilters] = useState([]);

  const searchIntent = route.params?.searchIntent;
  const [query, setQuery] = useState(searchIntent ?? "");

  useEffect(() => {
    if (searchIntent) {
      setQuery(searchIntent);
    }
  }, [searchIntent]);

  return (
    <View style={{ flex: 1, backgroundColor: staticColors.light.background }}>
      <ScrollView
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      >
        <DefaultBackHeader title="Globale Suche" showPatientData />
        <View
          style={{
            paddingHorizontal: Sizes.defaultContainerPadding,
          }}
        >
          <SearchBar
            shouldFocus
            placeholder={I18n.t("default_search_placeholder")}
            {...{ query, setQuery, selected, setSelected, filters, setFilters }}
            style={{ marginBottom: 8, marginTop: 24 }}
          />

          <View
            style={{
              flex: 1,
              alignSelf: "stretch",
              //marginHorizontal: Sizes.defaultContainerPadding,
            }}
          >
            <SearchResults isGlobalSearch {...{ query, setQuery, filters }} />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
