import staticColors from "../../../constants/static-colors";
import { isNumeric } from "../../../functions/current-result/helpers";

function cleanUnit(input) {
  // Entfernt HTML-Tags und Klammern
  return input
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/[()]/g, "")
    .trim();
}

function processSegment(segment: string, amount: number, unit: string): string {
  const u = getUnit(segment);

  let maxMinValues: any = { max: undefined, min: undefined };

  if (segment.includes("$MAX=") || segment.includes("$MIN=")) {
    maxMinValues = getMaxMinValues(segment);
  }

  // Remove $MAX and $MIN indicators from the segment
  segment = segment.replace(/\$MAX=[+-]?([0-9]*[,])?[0-9]+/g, "");
  segment = segment.replace(/\$MIN=[+-]?([0-9]*[,])?[0-9]+/g, "");

  const calcAmount = getCalcAmount(segment, u.length);
  const ranges = calcAmount.hm ? calcAmount.cm.split("-") : [calcAmount.cm];

  let didApplyMin = false;
  let didApplyMax = false;
  let appliedValue = "";

  // Process ranges, applying min/max where applicable
  const computedNumbers = ranges.map((rangeValue) => {
    let value: number = parseFloat(rangeValue.replace(/,/g, "."));
    value *= amount;

    // Apply min and max constraints
    if (maxMinValues.min && value < maxMinValues.min.v) {
      value = maxMinValues.min.v;
      didApplyMin = true;
      appliedValue = value + ""; // Store the original string value for min
    }

    if (maxMinValues.max && value > maxMinValues.max.v) {
      value = maxMinValues.max.v;
      didApplyMax = true;
      appliedValue = value + ""; // Store the original string value for max
    }

    // Round and format the value
    value = Math.round(value * 100) / 100;
    let valueString: string = value + "";
    valueString = valueString.replace(/\./g, ",");
    return valueString;
  });

  const uniqueNumbers = [...new Set(computedNumbers)];
  let resultString =
    uniqueNumbers.length > 1
      ? `${uniqueNumbers[0]} - ${uniqueNumbers[1]}`
      : `${uniqueNumbers[0]}`;

  // Wrap the result string in styled HTML
  resultString = `<b style='color: ${staticColors["light"].blue};'>${resultString}`;

  segment = segment.replace(
    new RegExp(
      "(?<!\\d)" + calcAmount.cm + "\\s+" + cleanUnit(u.unit) + "(?!/)",
      "g"
    ),
    `${resultString} ` + u.unit
  );

  const originalUnit = u.unit;

  // Handle unit formatting at the end of the segment
  if (segment.endsWith(originalUnit)) {
    let cleanedUnit = originalUnit.replace("/" + unit, "");
    const splitUnit = unit.split("/").filter((item) => item !== "");
    if (splitUnit.length > 1) {
      cleanedUnit += "/" + splitUnit[1];
    }
    const formattedUnit = cleanedUnit + "</b>";
    segment = segment.slice(0, -originalUnit.length) + formattedUnit;
  }

  const originalRange = calcAmount.cm.trim().replace(/\./g, ",") + " " + u.unit;

  // Check if a time unit (e.g., /h, /kg/h) exists in the segment

  let detectedTimeUnit = unit.split("/").filter((item) => item !== "")?.[1];

  if (!detectedTimeUnit) {
    detectedTimeUnit = "";
  } else {
    detectedTimeUnit = "/" + detectedTimeUnit;
  }

  // Add the max/min values and time unit to the segment
  if (didApplyMin || didApplyMax) {
    segment += ` (${originalRange}${unit} max. ${appliedValue} ${
      u.unit + detectedTimeUnit
    })`;
  } else if (maxMinValues.max) {
    segment += ` (${originalRange}${unit} max. ${maxMinValues.max.v} ${
      u.unit + detectedTimeUnit
    })`;
  } else if (maxMinValues.min) {
    segment += ` (${originalRange}${unit} min. ${maxMinValues.min.v} ${
      u.unit + detectedTimeUnit
    })`;
  } else {
    segment += ` (${originalRange}${unit})`;
  }

  return segment;
}

const BODYWEIGHT = "KG";

export default function getUnitParsedText(content, amount, unit) {
  const nocalcSections: any = [];
  content = content.replace(
    /&lt;nocalc&gt;(.*?)&lt;\/nocalc&gt;/g,
    (match, p1) => {
      nocalcSections.push(p1);
      return `<!--NO_CALC${nocalcSections.length - 1}-->`;
    }
  );

  // Enhanced regex to check for unit followed optionally by "/<any_string>"
  const regex = new RegExp(`/(${unit}(?:/[^<\\s]+)?)`, "g");

  const segmentUnitPairs: any = [];
  let lastIndex = 0;
  content.replace(regex, (match, p1, offset) => {
    // Capture the text before the unit as a segment and the unit itself
    const segment = content.substring(lastIndex, offset);
    if (segment) {
      segmentUnitPairs.push({ segment, unit: match });
    }
    lastIndex = offset + match.length;
  });

  // Add the remaining part of the string, if any
  if (lastIndex < content.length) {
    segmentUnitPairs.push({ segment: content.substring(lastIndex), unit: "" });
  }

  const bodyweightRegex = new RegExp(BODYWEIGHT, "g");

  // Process each segment with its corresponding unit
  const processedSegments = segmentUnitPairs.map((pair) => {
    // Here you would adjust the processing based on the presence of the unit
    // and potentially call `processSegment` with the segment and its specific unit
    const processedSegment = pair.segment.replace(bodyweightRegex, "");
    return pair.unit
      ? processSegment(processedSegment, amount, pair.unit)
      : processedSegment;
  });

  let finalString = processedSegments.join("");

  nocalcSections.forEach((nocalc, index) => {
    finalString = finalString.replace(`<!--NO_CALC${index}-->`, nocalc);
  });

  return finalString;
}

interface ValueResult {
  v: number;
  i: number;
}

// $COND={0;25="5 g/kg", 25;50="10 g/kg", 50;75="15 g/kg", 75;100="20 g/kg"}

function getMaxMinValues(m: string): { max?: ValueResult; min?: ValueResult } {
  function getAmount(s: string): ValueResult {
    const match = s.match(/^([0-9,.]+)/);
    if (!match) return { v: NaN, i: 0 };

    return { v: parseFloat(match[0].replace(/,/g, ".")), i: match[0].length };
  }

  const maxMatch = m.match(/\$MAX=([0-9,.]+)/);
  const minMatch = m.match(/\$MIN=([0-9,.]+)/);

  let max: ValueResult | undefined;
  let min: ValueResult | undefined;

  if (maxMatch) max = getAmount(maxMatch[1]);
  if (minMatch) min = getAmount(minMatch[1]);

  return { max, min };
}

interface CalcAmountResult {
  cm: string;
  hm: boolean;
}

function getCalcAmount(m: string, tailIndex: number): CalcAmountResult {
  let i = tailIndex + 1;
  let hasMultiple = false;

  while (i < m.length && /[\d,.\- ]/.test(m[m.length - i])) {
    if (m[m.length - i] === "-") {
      hasMultiple = true;
    }
    i++;
  }

  let calcAmount = m.slice(m.length - i + 1, m.length - tailIndex - 1).trim();

  // Ensure the calcAmount doesn't start with a dot
  while (calcAmount.startsWith(".")) {
    calcAmount = calcAmount.substring(1);
  }

  return { cm: calcAmount.trim(), hm: hasMultiple };
}

interface UnitResult {
  unit: string;
  length: number;
}

function getUnit(m: string): UnitResult {
  let i = 0;

  while (
    i < m.length &&
    m[m.length - 1 - i] !== " " &&
    !isNumeric(m[m.length - 1 - i])
  ) {
    i++;
  }

  const unitStr = m.slice(m.length - i, m.length);
  return { unit: unitStr, length: i };
}

export function getIndicesOf(
  searchStr: string,
  str: string,
  caseSensitive = false
): number[] {
  if (!searchStr.length) return [];

  const indices: number[] = [];
  let startIndex = 0;

  // Convert strings to lowercase if search is not case-sensitive
  const targetStr = caseSensitive ? str : str.toLowerCase();
  const searchString = caseSensitive ? searchStr : searchStr.toLowerCase();

  let index = targetStr.indexOf(searchString, startIndex);
  while (index !== -1) {
    indices.push(index);
    startIndex = index + searchString.length;
    index = targetStr.indexOf(searchString, startIndex);
  }

  return indices;
}
