import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Platform, Share, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import {
  selectPatientData,
  setCalculationVar,
} from "../../../functions/calculator/actions";

import { Feather } from "@expo/vector-icons";
import ControlledScrollView from "../../../components/ControlledScrollView";
import DefaultHeader from "../../../components/header/DefaultHeaderComponents";
import SearchSection from "../../../components/partnerships/SearchSection";
import { selectAllContent } from "../../../functions/data/actions";
import { setCurrentTabSelection } from "../../../functions/navigation/actions";
import { navigateToNextCMSPage } from "../../../functions/navigation/helpers";
import getColorScheme from "../../../hooks/useColorScheme";
import useScrollTopMargin from "../../../hooks/useScrollTopMargin";
import useShowContentPartner from "../../../hooks/useShowContentPartner";
import { SearchBar } from "../components/SearchBar";
import ContentListComponent from "./components/ContentListComponent";

export default function SearchScreen() {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const colorScheme = getColorScheme();

  const showContentPartner = useShowContentPartner();

  const paddingTop = useScrollTopMargin();
  const patientData = useSelector(selectPatientData);

  const medicalSubjectAreas =
    useSelector(selectAllContent).contentList?.content?.medicalSubjectAreaItems;

  const internalContent = medicalSubjectAreas?.find(
    (area) => area.title === "Hausinterne Inhalte"
  );
  const hasInternalContent = internalContent?.initialPageId;

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "search_screen_opened",
        "SearchScreen",
        "UserOpened",
        null
      );
      dispatch(setCurrentTabSelection(i18n.t("content")));

      dispatch(setCalculationVar(0));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        overflow: "visible",
        //backgroundColor: Colors[colorScheme].background,
      }}
    >
      <ControlledScrollView
        style={{
          flex: 1,
          padding: Sizes.defaultContainerPadding,
          paddingTop: 0,
        }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 150, paddingTop }}
      >
        <DefaultHeader height={150} />
        {!patientData && (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignSelf: "center",
              padding: 12,
              gap: 6,
              position: "absolute",
              top: 78 + paddingTop,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: Colors[colorScheme].foreground + "55",
              borderRadius: 30,
              paddingVertical: 10,
            }}
            onPress={() => {
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "share_app_selected",
                "SearchScreen",
                "User Selected Share App"
              );

              if (Platform.OS === "web") {
                navigator.clipboard.writeText("https://app.mediceo.com");
                alert("Link in die Zwischenablage kopiert!");
                return;
              }
              Share.share({
                message: "https://app.mediceo.com",
              });
            }}
          >
            <Text
              style={{
                fontSize: Sizes.boxText,
                fontWeight: "600",
              }}
            >
              App teilen
            </Text>
            <Feather name="share" size={18} color="black" />
          </TouchableOpacity>
        )}
        <View
          style={{
            marginBottom: 24,
          }}
        >
          <SearchBar
            action={() => {
              navigation.navigate("GlobalSearchScreen");
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "global_search_selected",
                "HomeScreen",
                "SearchBar"
              );
            }}
            placeholder={i18n.t("default_search_placeholder")}
            style={{ marginBottom: 8, marginTop: 8 }}
          />
        </View>
        {showContentPartner && <SearchSection />}
        {hasInternalContent && (
          <View
            style={{
              marginBottom: 24,
              paddingBottom: 24,
              borderBottomColor: "#bbb",
              borderBottomWidth: 0.3,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                navigateToNextCMSPage(internalContent.initialPageId, navigation)
              }
              style={{
                padding: 16,
                gap: 12,
                flexDirection: "row",
                borderRadius: 12,
                alignItems: "center",
                backgroundColor: Colors["light"].foreground,
              }}
            >
              <Text
                style={{
                  fontSize: Sizes.boxText,
                  fontWeight: "500",
                  flex: 1,
                }}
              >
                Hausinterne Inhalte
              </Text>
              <Feather name="chevron-right" size={20} />
            </TouchableOpacity>
          </View>
        )}
        <ContentListComponent />
      </ControlledScrollView>
    </View>
  );
}
