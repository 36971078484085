import React, { createContext, useContext, useEffect, useState } from "react";
import { loadPlacements } from "../../api/content/load-all/load-all-content";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface Logo {
  logoName: string;
  logoRef: string;
  logoId: string;
  logoWidth: number;
  isDefault: boolean;
}

interface PrimeTile {
  slogan: string;
  logo: Logo;
}

export interface SatelliteTile {
  slogan?: string;
  title?: string;
  campaignTrackingId: string;
  tileTrackingId: string;
  resultpageId: string;
}

interface DayData {
  primeTile: PrimeTile[];
  sateliteTile: SatelliteTile[];
  banner: BannerTile[];
}

interface BannerTile {
  slogan: string;
  logo: Logo;
}

interface DataState {
  [day: number]: DayData;
}

interface DataContextProps {
  data: DataState;
  loading: boolean;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<DataState>({});
  const [loading, setLoading] = useState(true);

  const today = new Date();
  const dayOfMonth = today.getDate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const cachedData = await AsyncStorage.getItem("placements");
        if (cachedData) {
          setData(JSON.parse(cachedData));
          setLoading(false);
        }
        const placements = await loadPlacements(today);
        setData(placements);
        AsyncStorage.setItem("placements", JSON.stringify(placements));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dayOfMonth]);

  return (
    <DataContext.Provider value={{ data, loading }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use data context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
