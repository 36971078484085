import { useNavigation } from "@react-navigation/native";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import Colors from "../../constants/static-colors";
import { selectCurrentUser } from "../../functions/user/actions";

import { Feather } from "@expo/vector-icons";
import { addEventListener } from "@react-native-community/netinfo";
import { useEffect, useState } from "react";
import { selectEnterprise } from "../../functions/enterprise/actions";
import { selectTabSelection } from "../../functions/navigation/actions";
import handleOneTimeAction from "../../functions/one-time-action-handler";
import getColorScheme from "../../hooks/useColorScheme";

import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { Sizes } from "../../constants/static-sizes";
import { selectPatientData } from "../../functions/calculator/actions";
import configureAnimations from "../../functions/configure-animations";
import { selectShowBadge } from "../../functions/data/actions";
import BadgeComponent from "../BadgeComponent";
import { UserIcon } from "../UserIconComponent";
import { HorizontalLogo } from "../logos/HorizontalLogo";
import OfflineHintModal from "../modals/OfflineHintModal";
import { PatientDataHeaderComponent } from "./header-components/PatientDataHeaderComponent";
import StreakIndicator from "./header-components/StreakComponent";

const LeftHeaderComponent = ({ title }) => {
  const navigation: any = useNavigation();

  const colorScheme = getColorScheme();
  const currentEnterprise = useSelector(selectEnterprise);

  const showLogo = currentEnterprise && currentEnterprise.logo?.show_logo;

  if (title.includes("Home"))
    return (
      <TouchableOpacity
        activeOpacity={0.65}
        style={{
          paddingVertical: 16,
          flexDirection: "row",
          alignItems: "center",

          gap: 10,
        }}
        hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
        onPress={() => {
          navigation.navigate("HomeScreen");
        }}
      >
        {showLogo && (
          <Image
            source={{ uri: currentEnterprise.logo?.file_ref }}
            style={{
              width: 80, //currentEnterprise?.logo?.logo_width ?? 45,
              height: 48,
              resizeMode: "contain",
            }}
          />
        )}

        {showLogo && (
          <Text
            style={{
              fontWeight: "700",
              fontSize: 20,
              marginBottom: 5,
              alignSelf: "flex-end",
              height: 28,
              color: Colors[colorScheme].text,
            }}
          >
            x
          </Text>
        )}
        <View>
          <HorizontalLogo />
        </View>
      </TouchableOpacity>
    );

  return (
    <Text
      style={{
        fontSize: 32,
        fontWeight: "700",
        height: 35,
        marginVertical: 16,
        color: Colors[colorScheme].text,
      }}
    >
      {title}
    </Text>
  );
};

export default function DefaultHeader({ height = 120, bottomOffset = 0 }) {
  const colorScheme = getColorScheme();

  const currentTabSelection = useSelector(selectTabSelection);
  const currentUser = useSelector(selectCurrentUser);

  const navigation = useNavigation();
  const [isOffline, setIsOffline] = useState(false);
  const [showHintBadge, setShowHintBadge] = useState(false);
  const [showOfflineHint, setShowOfflineHint] = useState(false);

  const showBadge = useSelector(selectShowBadge);

  const patientData = useSelector(selectPatientData);

  const imageToTitle = (title) => {
    if (title === "Home")
      return require(`../../../assets/images/header/home.png`);
    if (title === "Inhalte")
      return require(`../../../assets/images/header/content.png`);
    if (title === "Persönlich")
      return require(`../../../assets/images/header/personal.png`);
  };

  useEffect(() => {
    const unsubscribe = addEventListener((state) => {
      configureAnimations();
      setIsOffline(
        !state.isConnected ||
          (state.type === "cellular" &&
            state.details.cellularGeneration === "2g")
      );
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function checkShowBadge() {
      if (isOffline) {
        if (await handleOneTimeAction("show_offline_hint")) {
          setShowHintBadge(true);
        }
      }
    }

    checkShowBadge();
  }, [isOffline]);

  return (
    <View>
      <OfflineHintModal
        open={showOfflineHint}
        onClose={() => setShowOfflineHint(false)}
      />

      <View
        style={{
          position: "absolute",
          top: -150,
          left: -Sizes.defaultContainerPadding,
          right: -Sizes.defaultContainerPadding,
          zIndex: 0,
          overflow: "hidden",
          bottom: -36 + bottomOffset,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <Image
          source={imageToTitle(currentTabSelection)}
          style={{
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
      <View
        style={{
          minHeight: height,
          marginBottom: bottomOffset,
        }}
      >
        {!patientData && (
          <View
            style={{
              zIndex: 1,
              height: 70,
              paddingBottom: 4,
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LeftHeaderComponent title={currentTabSelection} />
            <View
              style={{
                flexDirection: "row",
                gap: 20,
                alignItems: "center",
              }}
            >
              {isOffline && (
                <TouchableOpacity
                  hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
                  onPress={() => {
                    setShowHintBadge(false);
                    setShowOfflineHint(true);
                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "offline_hint_modal_opened",
                      "DefaultHeader",
                      "User Opened Offline Hint Modal",
                      { isOffline }
                    );
                  }}
                >
                  {showHintBadge && <BadgeComponent text="1" />}

                  <Feather
                    name="wifi-off"
                    color={Colors[colorScheme].text}
                    size={24}
                  />
                </TouchableOpacity>
              )}
              <StreakIndicator />

              <TouchableOpacity
                onPress={() => {
                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "more_screen_navigation",
                    "DefaultHeader",
                    "User Navigated to More Screen",
                    null
                  );

                  navigation.navigate("MoreScreen");
                }}
              >
                <UserIcon
                  {...{ colorScheme, currentUser }}
                  size={Platform.OS === "web" ? 52 : 48}
                />
                {showBadge && (
                  <BadgeComponent style={{ top: -5, right: -5 }} text="1" />
                )}
              </TouchableOpacity>
            </View>
          </View>
        )}
        <View
          style={{
            marginBottom: -bottomOffset + 12,
          }}
        >
          <PatientDataHeaderComponent />
        </View>
      </View>
    </View>
  );
}
