import { ScrollView, Text, TouchableOpacity, View } from "react-native";

import { Feather } from "@expo/vector-icons";
import { useState } from "react";

import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import apiCall from "../../../api/api-call";
import alert from "../../../components/CustomAlert";
import ChangePasswordModal from "../../../components/modals/ChangePasswordModal";
import ChangeUserInformationModal from "../../../components/modals/ChangeUserInformationModal";
import Colors from "../../../constants/static-colors";
import { logout } from "../../../functions/profile/functions";
import { selectCurrentUser } from "../../../functions/user/actions";
import { isWebAndNotMobile } from "../../../hooks/isLargerDevice";
import getColorScheme from "../../../hooks/useColorScheme";

export const AccountScreen = () => {
  const colorScheme = getColorScheme();
  const currentUser = useSelector(selectCurrentUser);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [
    changeUserInformationModalVisible,
    setChangeUserInformationModalVisible,
  ] = useState(false);
  const [changeUserInformation, setChangeUserInformation] = useState({
    title: "",
    placeholder: "",
    indexKey: "",
  });
  const navigation = useNavigation();

  function showChangeUserInformation(title, placeholder, indexKey) {
    setChangeUserInformation({ title, placeholder, indexKey });
    setChangeUserInformationModalVisible(true);
  }

  function deleteAccount() {
    alert("Konto löschen", "Möchtest du wirklich dein Konto löschen?", [
      {
        text: "Nein",
        style: "cancel",
      },
      {
        text: "Entfernen",
        style: "destructive",
        onPress: () => {
          _delete();
        },
      },
    ]);
  }

  async function _delete() {
    const result = await apiCall(
      "/api/v2/users/" + currentUser.user_id,
      {},
      false,
      false,
      "DELETE"
    );
    if (result) logout(currentUser, navigation);
  }

  return (
    <View
      style={{
        paddingHorizontal: isWebAndNotMobile() ? 32 : 0,
        backgroundColor: Colors[colorScheme].background,
        flex: 1,
        paddingTop: 20,
      }}
    >
      <ChangePasswordModal
        open={passwordModalVisible}
        onClose={() => setPasswordModalVisible(false)}
      />
      <ChangeUserInformationModal
        indexKey={changeUserInformation.indexKey}
        placeholder={changeUserInformation.placeholder}
        title={changeUserInformation.title}
        open={changeUserInformationModalVisible}
        onClose={() => setChangeUserInformationModalVisible(false)}
      />
      <ScrollView>
        {getData(
          currentUser,
          setPasswordModalVisible,
          showChangeUserInformation,
          false
        )
          .filter((item) =>
            currentUser?.user_type !== "doc" ||
            currentUser?.user_type === "doctor"
              ? item.id !== 1
              : true
          )
          .map((item) =>
            item.title !== "Separator" ? (
              <ListItem
                title={item.title}
                text={item.text}
                key={item.id}
                action={item.action}
              />
            ) : (
              <Separator key={item.id} />
            )
          )}
        <Separator />
        <TouchableOpacity
          onPress={deleteAccount}
          style={{
            flexDirection: "row",
            paddingHorizontal: 16,
            marginVertical: 16,
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: Colors[colorScheme].red,
              fontSize: 15,
              fontWeight: "500",
              alignSelf: "center",
            }}
          >
            Mein Konto löschen
          </Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
};

const Separator = () => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        alignSelf: "stretch",
        height: 1,
        backgroundColor: Colors[colorScheme].text,
        opacity: 0.2,
        marginHorizontal: 16,
        marginVertical: 16,
      }}
    />
  );
};

const ListItem = ({ title, text, action }) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      style={{ padding: 16, flexDirection: "row" }}
      disabled={!action}
      onPress={action}
    >
      <Text
        style={{
          width: 115,
          fontSize: 15,
          fontWeight: "500",
          color: Colors[colorScheme].text,
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          flex: 1,
          opacity: 0.5,
          fontSize: 15,
          color: Colors[colorScheme].text,
        }}
      >
        {text}
      </Text>
      {action && (
        <Feather name="edit" size={20} color={Colors[colorScheme].text} />
      )}
    </TouchableOpacity>
  );
};

const getData = (
  currentUser,
  setPasswordModalVisible,
  showChangeUserInformation,
  isEnterprise
) => [
  ...(isEnterprise === false
    ? [
        {
          id: 1,
          title: "Titel",
          text: currentUser.title,
          action: () =>
            showChangeUserInformation("Neuen Titel eingeben", "Titel", "title"),
        },
      ]
    : []),
  ...(isEnterprise === false
    ? [
        {
          id: 2,
          title: "Vorname",
          text: currentUser.firstname,
          action: () =>
            showChangeUserInformation(
              "Neuen Vornamen eingeben",
              "Vorname",
              "firstname"
            ),
        },
      ]
    : []),
  ...(isEnterprise === false
    ? [
        {
          id: 3,
          title: "Nachname",
          text: currentUser.lastname,
          action: () =>
            showChangeUserInformation(
              "Neuen Nachnamen eingeben",
              "Nachname",
              "lastname"
            ),
        },
      ]
    : []),
  ...(isEnterprise === false
    ? [
        {
          id: 4,
          title: "Separator",
        },
      ]
    : []),
  {
    id: 5,
    title: "E-Mail",
    text: currentUser.email,
  },
  {
    id: 6,
    title: "Passwort",
    text: "*********",
    action: () => setPasswordModalVisible(true),
  },
  {
    id: 7,
    title: "Separator",
  },
  {
    id: 8,
    title:
      currentUser?.user_type === "student"
        ? "Universität"
        : currentUser?.user_type === "enterprise-lite"
        ? "Institution"
        : "Klinik / Praxis / Sonstiges",
    text: currentUser.clinic ?? currentUser?.customer_name ?? "-",
    action: () =>
      showChangeUserInformation(
        currentUser?.user_type === "student"
          ? "Neue Universität eingeben"
          : "Neue Klinik / Praxis / Sonstiges eingeben",
        currentUser?.user_type === "student"
          ? "Universität"
          : "Klinik / Praxis / Sonstiges",
        "clinic"
      ),
  },
  // {
  //   id: 8,
  //   title: "Position",
  //   text: currentUser.position ?? "nicht eingetragen",
  //   action: () =>
  //     showChangeUserInformation("Position ändern", "Position", "clinic"),
  // },
  {
    id: 9,
    title: "Nutzerart",
    text: displayToUserRole(currentUser?.user_type),
  },
];

function displayToUserRole(user_type) {
  switch (user_type) {
    case "doc":
    case "doctor":
      return "Arzt / Ärztin";
    case "nurse":
      return "Nicht ärztliches Fachpersonal";
    case "student":
      return "Studierende";
    case "enterprise-lite":
      return "Enterprise";
    case "dev":
      return "MEDICEO (intern)";
    default:
      return "Nicht medizinisch";
  }
}
