import {
  Feather,
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { Annotation } from "../../../api/content/load-all/types";
import {
  default as Colors,
  default as staticColors,
} from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { showLightboxImage } from "../../../entry/Root";
import { configureExpansionAnimation } from "../../../functions/configure-animations";
import getColorScheme from "../../../hooks/useColorScheme";
import AnimatedWrapper from "../../AnimatedWrapper/AnimatedWrapper";
import { BoxTextComponent } from "../../custom-box-text/CustomBoxTextComponent";
import ImageWrapper from "../../ImageWrapper";
import {
  getSharedStylesDosingRecommendation,
  getSharedStylesLargeContent,
} from "../SharedStyles";
import AnnotationComponent from "./AnnotationComponent";
import { InlineImage } from "./inline-image-types";

function getColorToType(type, colorScheme) {
  if (!type) type = "";
  switch (type.toLowerCase()) {
    case "warning":
      return Colors[colorScheme].red;
    case "hint":
      return Colors[colorScheme].gray;
    case "action":
      return Colors[colorScheme].green;
    case "dosing":
      return Colors[colorScheme].blue;
    case "largecontent":
      return Colors[colorScheme].accent;
    default:
      return Colors[colorScheme].green;
  }
}

export default function LargeStandardBox({
  title,
  content,
  annotation,
  inlineImage,
  iconType,
  boxType,
  expandableContent,
  insertedContent,
  isNote,
}: {
  readonly title: string;
  readonly content: string;
  readonly annotation?: Annotation;
  readonly inlineImage?: InlineImage;
  readonly iconType?: string;
  readonly boxType?: string;
  readonly expandableContent?: string;
  readonly insertedContent?: any;
  readonly isNote?: boolean;
}) {
  const colorScheme = getColorScheme();
  const boxAccentColor = getColorToType(boxType, colorScheme);

  const backgroundColor =
    //hasBackgroundColor
    // ? pSBC(colorScheme === "light" ? 0.7 : -0.915, boxAccentColor) ??
    //   "transparent"
    //:
    Colors[colorScheme].foreground;

  const [expanded, setExpanded] = useState(false);

  const styles = getStyles(
    colorScheme,
    boxAccentColor,
    content || expanded || insertedContent,
    backgroundColor
  );

  const hasInlineImage = inlineImage?.image?.file_ref ?? inlineImage?.url;

  const imageInExpandable = inlineImage?.isInExpandable;

  const imageURI =
    inlineImage?.image?.file_ref ?? "https://" + inlineImage?.url;

  return (
    <View style={styles.outerContainer}>
      <View style={styles.innerContainer}>
        <AnnotationComponent
          annotation={annotation}
          colorScheme={colorScheme}
        />
        <View style={styles.contentContainer}>
          <View style={styles.accentBar} />

          <View style={styles.columnContainer}>
            <TouchableOpacity
              disabled={!(!content && expandableContent) && !imageInExpandable}
              hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
              onPress={() => {
                configureExpansionAnimation();

                setExpanded((e) => !e);

                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  expanded ? "content_collapsed" : "content_expanded",
                  "LargeStandardBox",
                  `User ${expanded ? "Collapsed" : "Expanded"} Content`,
                  { title }
                );
              }}
              style={styles.titleContainer}
            >
              {((!content && expandableContent) || imageInExpandable) && (
                <Feather
                  name={expanded ? "chevron-up" : "chevron-down"}
                  size={24}
                  color={staticColors[colorScheme].text}
                  style={{ marginRight: 0 }}
                />
              )}
              {isNote && (
                <View style={{ flexDirection: "row", gap: 4 }}>
                  <Feather
                    name="edit"
                    size={20}
                    color={Colors[colorScheme].text}
                    style={{
                      opacity: 0.5,
                    }}
                  />
                </View>
              )}
              <View style={{ flex: 1, marginRight: 8 }}>
                <BoxTextComponent
                  style={getSharedStylesDosingRecommendation(
                    Colors[colorScheme].text
                  )}
                  color={boxAccentColor}
                  content={"<b>" + title + "</b>"}
                />
              </View>
              {iconType && (
                <View style={{ marginRight: 0 }}>
                  <IconToType type={iconType} />
                </View>
              )}
            </TouchableOpacity>

            {(!!content || expanded || insertedContent) && (
              <TouchableOpacity
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                onPress={() => {
                  configureExpansionAnimation();

                  setExpanded((e) => !e);

                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    expanded ? "content_collapsed" : "content_expanded",
                    "LargeStandardBox",
                    `User ${expanded ? "Collapsed" : "Expanded"} Content`,
                    { title }
                  );
                }}
                disabled={!(content && expandableContent)}
                style={{
                  ...styles.textContainer,
                  //...Platform.select({
                  //   web: { transition: `opacity 500ms ease-in-out` },
                  // }),
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {!insertedContent && (
                    <BoxTextComponent
                      color={boxAccentColor}
                      style={getSharedStylesLargeContent(colorScheme)}
                      content={content}
                    />
                  )}
                  {insertedContent && (
                    <View style={{ flex: 1 }}>{insertedContent}</View>
                  )}
                </View>
                {expandableContent && !!content && (
                  <Feather
                    name={expanded ? "chevron-up" : "chevron-down"}
                    color={staticColors[colorScheme].text}
                    size={24}
                    style={{ marginRight: 0 }}
                  />
                )}
              </TouchableOpacity>
            )}

            {hasInlineImage && !imageInExpandable && (
              <TouchableOpacity
                onLongPress={() =>
                  showLightboxImage({
                    uri: imageURI,
                  })
                }
                onPress={() =>
                  showLightboxImage({
                    uri: imageURI,
                  })
                }
                style={styles.imageContainer}
              >
                <ImageWrapper
                  style={{
                    ...styles.imageStyle,
                    aspectRatio: inlineImage?.aspectRatio
                      ? 1 / inlineImage?.aspectRatio
                      : 9 / 16,

                    maxHeight: 700,
                    width: "100%",
                    alignSelf: "center",
                  }}
                  source={{ uri: imageURI }}
                />
              </TouchableOpacity>
            )}
            <AnimatedWrapper style={{ width: "100%", height: "100%" }}>
              {expanded && (
                <View style={{ ...styles.textContainer }}>
                  <View style={{ flex: 1, marginTop: -20 }}>
                    <BoxTextComponent
                      color={boxAccentColor}
                      style={getSharedStylesLargeContent(colorScheme)}
                      content={expandableContent}
                    />
                    {hasInlineImage && imageInExpandable && (
                      <TouchableOpacity
                        onLongPress={() =>
                          showLightboxImage({
                            uri: imageURI,
                          })
                        }
                        onPress={() =>
                          showLightboxImage({
                            uri: imageURI,
                          })
                        }
                        style={styles.imageContainer}
                      >
                        <ImageWrapper
                          style={{
                            ...styles.imageStyle,
                            aspectRatio: inlineImage?.aspectRatio
                              ? 1 / inlineImage?.aspectRatio
                              : 9 / 16,

                            maxHeight: 700,
                            width: "100%",
                            alignSelf: "center",
                          }}
                          source={{ uri: imageURI }}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              )}
            </AnimatedWrapper>
          </View>
        </View>
      </View>
    </View>
  );
}

const getStyles = (
  colorScheme,
  boxAccentColor,
  showBorder,
  backgroundColor
) => {
  const styles = StyleSheet.create({
    outerContainer: {
      marginTop: 20,
      width: "100%",
      overflow: "hidden",
    },
    innerContainer: {
      borderRadius: 10,
      backgroundColor,
      overflow: "hidden",
    },
    contentContainer: {
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
    },
    accentBar: {
      zIndex: 100,
      height: "100%",
      width: 7,
      position: "absolute",
      backgroundColor: boxAccentColor,
    },
    columnContainer: {
      flexDirection: "column",
      width: "100%",
      paddingLeft: 7,
      alignItems: "center",
    },
    titleContainer: {
      padding: Sizes.defaultBoxPadding,
      width: "100%",
      flexDirection: "row",
      borderBottomColor: boxAccentColor,
      alignItems: "center",
      borderBottomWidth: showBorder ? 2 : 0,
      gap: 8,
    },
    textContainer: {
      marginHorizontal: Sizes.defaultBoxPadding,
      paddingVertical: Sizes.defaultBoxPadding,
      alignSelf: "stretch",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      gap: 18,
    },
    imageContainer: {
      alignItems: "center",
      alignSelf: "stretch",
      overflow: "hidden",
      paddingRight: 3.5,
    },
    imageStyle: {
      flex: 1,
      resizeMode: "contain",
      marginBottom: 4,
      borderBottomEndRadius: 8,
      marginTop: 0,
      width: "100%",
      alignSelf: "stretch",
    },
  });
  return styles;
};

const IconToType = ({ type }) => {
  const colorScheme = getColorScheme();

  switch (type) {
    case "temperature":
      return (
        <FontAwesome
          name="thermometer-half"
          size={20}
          color={Colors[colorScheme].text}
        />
      );
    case "material":
      return (
        <FontAwesome5 name="tools" size={18} color={Colors[colorScheme].text} />
      );
    case "labor":
      return (
        <MaterialCommunityIcons
          name="test-tube"
          size={20}
          color={Colors[colorScheme].text}
        />
      );
    case "cardio":
      return (
        <Feather name="activity" size={20} color={Colors[colorScheme].text} />
      );
    case "Kein Icon":
      return null;
    case "medication":
    default:
      return (
        <MaterialCommunityIcons
          name="pill"
          size={20}
          color={Colors[colorScheme].text}
        />
      );
  }
};
