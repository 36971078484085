import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import getPageContent from "../../../../api/content/get-page-content";
import staticColors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import {
  setChecklistData,
  setChecklistNote,
} from "../../../../functions/calculator/actions";
import configureAnimations from "../../../../functions/configure-animations";
import getColorScheme from "../../../../hooks/useColorScheme";
import {
  ChecklistInterpretation,
  getCheckedMainItems,
  getChecklistColor,
  isSublistChecked,
} from "../../../../screens/ChecklistScreen/ChecklistScreen";
import { CategoryIcon } from "../../../CategoryIcon";
import { ChecklistComponent } from "../../../ChecklistComponent";

export default function ChecklistFurtherActionBox({ box }) {
  const isPopover = true; //box.isPopover;

  const pageContent = useMemo(
    () => getPageContent(box.resultOrNodeID),
    [box.resultOrNodeID]
  );

  const [checkedItems, setCheckedItems] = useState<any>(new Set());
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    dispatch(setChecklistData(checkedItems));
  }, [checkedItems]);

  useEffect(() => {
    dispatch(setChecklistNote(note));
  }, [note]);

  const colorScheme = getColorScheme();

  const listLength = pageContent?.checklistItems.filter(
    (item) => !item.isOptional && item.typeId === "checklistListElement"
  ).length;

  const completed = pageContent?.checklistItems.every((item) => {
    if (item.typeId !== "checklistListElement") return true;
    if (item.isOptional) {
      return true;
    }
    if (item.listElements) {
      return isSublistChecked(item.listElements, checkedItems);
    }
    return checkedItems?.has(item.id);
  });

  return (
    <View
      style={{
        marginTop: 20,
        borderRadius: 12,
        overflow: "hidden",
        paddingTop: expanded ? 12 : 0,
        paddingLeft: expanded ? 19 : 0,
        paddingRight: expanded ? 12 : 0,

        backgroundColor: staticColors[colorScheme].foreground,
      }}
    >
      {!expanded && (
        <View
          style={{
            width: 7,
            top: 0,
            bottom: 0,
            position: "absolute",
            left: 0,
            backgroundColor: getChecklistColor(completed, colorScheme),
            zIndex: 10,
          }}
        />
      )}

      <TouchableOpacity
        key={box.id}
        style={{
          flexDirection: "row",
          padding: expanded ? 8 : 16,
          paddingLeft: expanded ? 0 : 19,
          backgroundColor: expanded
            ? "transparent"
            : staticColors[colorScheme].foreground,
          alignItems: "center",
        }}
        onPress={() => {
          configureAnimations();
          if (isPopover) {
            navigation.navigate("ChecklistScreen", {
              pageContent,
              checkedItems,
              setCheckedItems,
              modal: true,
              setNote,
              note,
            });
            return;
          }
          setExpanded((e) => !e);
        }}
      >
        <CategoryIcon title={"Checklisten"} size={26} />
        <View style={{ flex: 1, gap: 4 }}>
          <Text
            style={{
              fontWeight: "700",
              fontSize: Sizes.boxText,
              color: staticColors[colorScheme].text,
            }}
          >
            {box.title}
          </Text>
          {!expanded && (
            <Text
              style={{
                color: staticColors[colorScheme].text,
                fontSize: Sizes.boxText,
                fontStyle: "italic",
              }}
            >
              {
                getCheckedMainItems(pageContent?.checklistItems, checkedItems)
                  .size
              }{" "}
              von {listLength} Punkten erledigt
            </Text>
          )}
        </View>
        <Feather
          name={
            isPopover
              ? "chevron-right"
              : expanded
              ? "chevron-up"
              : "chevron-down"
          }
          size={24}
          color={staticColors[colorScheme].text}
        />
      </TouchableOpacity>

      {!isPopover && expanded && (
        <ChecklistComponent
          pageContent={pageContent}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />
      )}
      {expanded && (
        <View
          style={{
            width: 7,
            top: 0,
            bottom: 0,
            position: "absolute",
            left: 0,

            backgroundColor: getChecklistColor(completed, colorScheme),
            zIndex: 1000,
          }}
        />
      )}
      {expanded && (
        <ChecklistInterpretation
          style={{
            marginHorizontal: -24,
            marginTop: 12,
          }}
          checklistItems={pageContent?.checklistItems}
          checkedItems={checkedItems}
          completed={completed}
          listLength={listLength}
        />
      )}
    </View>
  );
}
