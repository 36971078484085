import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { downloadImage } from "../../api/image-processor";
import { Sizes } from "../../constants/static-sizes";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import isLargerDevice from "../../hooks/isLargerDevice";
import ImageWrapper from "../ImageWrapper";

const LargePartnerCard = ({ item }) => {
  const navigation = useNavigation();
  // Determine the appropriate logo based on device size
  const selectedLogo = isLargerDevice()
    ? item.logo?.find((logo) => logo.fileName.includes("7,5-1"))
    : item.logo?.find((logo) => logo.fileName.includes("4-1"));

  useEffect(() => {
    downloadImage(selectedLogo?.fileUrl);
  }, [selectedLogo]);

  return (
    <TouchableOpacity
      style={styles.largeCard}
      onPress={() => {
        if (item.isInternalStatic) {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "prime_section_card_clicked",
            "PrimeSectionCard",
            "UserClicked",
            {
              campaignTrackingId: "static",
              tileTrackingId: item.resultpageId,
            }
          );
        } else {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "prime_section_card_clicked",
            "PrimeSectionCard",
            "UserClicked",
            {
              campaignTrackingId: item.campaignTrackingId,
              tileTrackingId: item.tileTrackingId,
            }
          );
        }

        navigateToNextCMSPage(item.resultpageId, navigation);
      }}
    >
      <View
        style={{
          borderRadius: 12,
          overflow: "hidden",
          aspectRatio: isLargerDevice() ? 7.5 : 4,
        }}
      >
        {selectedLogo ? (
          <ImageWrapper
            source={{ uri: selectedLogo?.fileUrl }}
            style={{
              width: "100%",
              height: "100%",
              resizeMode: "cover",
            }}
          />
        ) : (
          <Text
            style={{
              padding: 12,
              fontSize: Sizes.boxText,
              alignSelf: "center",
              flex: 1,
              textAlign: "center",
            }}
          >
            {item.title}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

// Styles for the components
const styles = StyleSheet.create({
  largeCard: {
    borderRadius: 12,
    width: "100%",
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    elevation: 2,
    shadowOpacity: 0.05,
    shadowRadius: 2,
    backgroundColor: "white",
  },
});

export default LargePartnerCard;
