import AsyncStorage from "@react-native-async-storage/async-storage";
import apiCall from "../api/api-call";
import { reloadUser } from "../screens/more/MoreScreen";
import { store } from "./store";
import { selectCurrentUser, updatePatientInUser } from "./user/actions";
import { getIsOffline } from "../components/search-tab/SearchResults";
import { updateOfflineUser } from "./user/functions";

const QUEUE_KEY = "interactionQueue";

export async function synchronizeInteraction(interaction) {
  const { action, payload, dispatch } = interaction;
  const isOffline = await getIsOffline();
  if (!isOffline) {
    try {
      await actionHandlers[action](payload);
      return true;
    } catch {
      return false;
    }
  }

  dispatch();
  const currentUser = store.getState().userReducer.user as any;
  updateOfflineUser(currentUser);
  const queue = JSON.parse(await AsyncStorage.getItem(QUEUE_KEY)) || [];
  queue.push(interaction);
  await AsyncStorage.setItem(QUEUE_KEY, JSON.stringify(queue));
  return false;
}

async function performApiCall({ url, method = "GET", body = null }) {
  try {
    const response = await apiCall(url, body, false, false, method);
    reloadUser(store.getState().userReducer.user, store.dispatch);
    return response;
  } catch (error) {
    reloadUser(store.getState().userReducer.user, store.dispatch);
    console.error(`API Call Error: ${url}`, error);
    throw error;
  }
}

export async function processQueue() {
  const queue = JSON.parse(await AsyncStorage.getItem(QUEUE_KEY)) || [];

  if (queue.length === 0) return;

  console.log("Zwischengespeicherte Interaktionen:", queue);

  while (queue.length > 0) {
    const interaction = queue[0]; // Hol das erste Element
    const { action, payload } = interaction;

    console.log(interaction);

    if (actionHandlers[action]) {
      try {
        await actionHandlers[action](payload);
        queue.shift();
        await AsyncStorage.setItem(QUEUE_KEY, JSON.stringify(queue));
      } catch (error) {
        console.error(`Fehler bei Aktion ${action}:`, error);
        break;
      }
    } else {
      console.error(`Unbekannte Aktion: ${action}`);
      queue.shift();
      await AsyncStorage.setItem(QUEUE_KEY, JSON.stringify(queue));
    }

    reloadUser(store.getState().userReducer.user, store.dispatch);
  }
}

const actionHandlers = {
  addBookmark: async (payload) =>
    performApiCall({
      url: `/api/v2/users/${payload.user_id}/shortcuts/${payload.id}`,
      method: "PUT",
    }),

  removeBookmark: async (payload) =>
    performApiCall({
      url: `/api/v2/users/${payload.user_id}/shortcuts/${payload.id}`,
      method: "DELETE",
    }),

  updatePatient: async (payload) =>
    performApiCall({
      url: `/api/patients/${payload.id}`,
      method: "PUT",
      body: payload,
    }),

  removePatient: async (payload) =>
    performApiCall({
      url: `/api/patients/${payload.id}`,
      method: "DELETE",
    }),

  addPageToPatientUpdate: async (payload) =>
    performApiCall({
      url: `/api/patients/patient_data/${payload.patient.id}`,
      method: "PUT",
      body: {
        result_page_id: payload.pageId,
        data: payload.data,
      },
    }),

  addPageToPatientCreate: async (payload) =>
    performApiCall({
      url: `/api/patients/patient_data`,
      method: "POST",
      body: {
        patient_id: payload.patient.id,
        result_page_id: payload.pageId,
        data: payload.data,
      },
    }),

  deletePageFromPatient: async (payload) =>
    performApiCall({
      url: `/api/patients/patient_data/${payload.data.id}`,
      method: "DELETE",
    }),

  addPatient: async (payload) =>
    performApiCall({
      url: `/api/patients/`,
      method: "POST",
      body: payload,
    }),

  addToUserHistory: async (payload) =>
    performApiCall({
      url: `/api/v2/users/result_page/history`,
      method: "POST",
      body: {
        user_email: payload.user_email,
        result_page_id: payload.id,
        liked: null,
      },
    }),

  updateNote: async (payload) =>
    performApiCall({
      url: `/api/v2/users/${payload.user_id}/notes/${payload.note.id}`,
      method: "PUT",
      body: {
        note: payload.note.note,
      },
    }),

  deleteNote: async (payload) =>
    performApiCall({
      url: `/api/v2/users/${payload.user_id}/notes/${payload.noteId}`,
      method: "DELETE",
    }),
};
