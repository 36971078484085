import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/Notifications/NotificationComponent";
import { showInlineNotification } from "../../entry/Root";
import { store } from "../store";

import i18n from "../../../locales/i18n";
import { setAmount } from "./actions";

export function checkBounds(title: string, value: string) {
  const number = parseFloat(value);

  function showUpperNotification() {
    showInlineNotification({
      text:
        i18n.locale === "de"
          ? "Der eingegebene Wert scheint hoch zu sein. Bist du sicher?"
          : i18n.t("value_to_high"),
      type: InlineNotificationType.ERROR,
      direction: InlineNotificationDirection.FROM_TOP,
    });
  }
  function showLowerNotification() {
    showInlineNotification({
      text:
        i18n.locale === "de"
          ? "Der eingegebene Wert scheint niedrig zu sein. Bist du sicher?"
          : i18n.t("value_to_low"),
      direction: InlineNotificationDirection.FROM_TOP,
    });
  }

  if (number === undefined) return;

  const item = keyValueUnit().find((item) =>
    title.toLowerCase().includes(item.value.toLowerCase())
  );
  if (!item) return;

  const { upper } = item;
  const { lower } = item;

  if (number > upper) {
    showUpperNotification();
  }
  if (number < lower) {
    showLowerNotification();
  }
}

export function resetValues(text, title, unit) {
  if (text === "") {
    const key =
      unit ?? keyValueUnit().find((item) => title.includes(item.value))?.key;
    store.dispatch(setAmount({ [key]: undefined }));
  }
}

export function setValues(title, text, unit) {
  const value = parseFloat(text.split(",").join("."));
  const key =
    unit ??
    keyValueUnit().find((item) =>
      title.toLowerCase().includes(item.value.toLowerCase())
    )?.key;
  const _value = value;
  store.dispatch(setAmount({ [key]: _value }));
}

export function getCurrentUnit(text: string, unit) {
  if (unit) return unit;
  return (
    keyValueUnit().find((item) =>
      text.toLowerCase().includes(item.value.toLowerCase())
    )?.unit ?? "kg"
  );
}

export const keyValueUnit = () => {
  if (i18n.locale !== "en")
    return [
      { key: "age", value: "Alter", unit: "Jahre", upper: 100, lower: 0 },
      { key: "qt", value: "QT-Dauer", unit: "msec", upper: 100000, lower: 0 },
      { key: "duration", value: "Dauer", unit: "h", upper: 100000, lower: 0 },
      { key: "loss", value: "Verluste", unit: "ml", upper: 100000, lower: 0 },
      {
        key: "serum_mg",
        value: "Serumkreatinin in mg/dl",
        unit: "mg/dl",
        upper: 100000,
        lower: 0,
      },
      {
        key: "serum",
        value: "Serumkreatinin in µmol",
        unit: "μmol/l",
        upper: 100000,
        lower: 0,
      },
      { key: "rr", value: "RR-Abstand", unit: "cm", upper: 100000, lower: 0 },
      { key: "umfang", value: "umfang", unit: "cm", upper: 100000, lower: 0 },
      { key: "size", value: "Körpergröße", unit: "m", upper: 2.1, lower: 0 },
      {
        key: "freq",
        value: "Frequenz",
        unit: "1/min",
        upper: 100000,
        lower: 0,
      },
      { key: "kg", value: "", unit: "kg", upper: 200, lower: 0 },
    ];
  else
    return [
      { key: "age", value: "Age", unit: "Years", upper: 100, lower: 0 },
      {
        key: "qt",
        value: "QT-Duration",
        unit: "msec",
        upper: 100000,
        lower: 0,
      },
      {
        key: "duration",
        value: "Duration",
        unit: "h",
        upper: 100000,
        lower: 0,
      },
      { key: "loss", value: "Losses", unit: "ml", upper: 100000, lower: 0 },
      {
        key: "serum_mg",
        value: "Serum creatinine in mg/dl",
        unit: "mg/dl",
        upper: 100000,
        lower: 0,
      },
      {
        key: "serum",
        value: "Serum creatinine in µmol",
        unit: "μmol/l",
        upper: 100000,
        lower: 0,
      },
      { key: "rr", value: "RR interval", unit: "cm", upper: 100000, lower: 0 },
      {
        key: "umfang",
        value: "Circumference",
        unit: "cm",
        upper: 100000,
        lower: 0,
      },
      { key: "size", value: "Body size", unit: "m", upper: 2.1, lower: 0 },
      {
        key: "freq",
        value: "Frequency",
        unit: "1/min",
        upper: 100000,
        lower: 0,
      },
      { key: "kg", value: "", unit: "kg", upper: 200, lower: 0 },
    ];
};

export const getPlaceholder = (title, isSize) => {
  if (isSize) return title.replace(/\(in m\)/g, "");
  else return title;
};

export function getRandom16DigitNumber() {
  const randomNum = Math.floor(Math.random() * 10 ** 4);
  return randomNum.toString().padStart(4, "0"); // Auffüllen mit führenden Nullen, falls nötig
}
