import messaging from "@react-native-firebase/messaging";
import { useNavigation } from "@react-navigation/native";
import { Platform, Text, View } from "react-native";

import { useEffect } from "react";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import getPageContent from "../api/content/get-page-content";
import { InlineNotificationType } from "../components/Notifications/NotificationComponent";

import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { APP_TARGET } from "../screens/LaunchScreen";
import { showInlineNotification } from "./Root";

export const NotificationHandlingWrapper = ({ children }) => {
  const navigation = useNavigation();

  const checkForInitialNotification = async (handleNotification) => {
    const remoteMessage = await messaging().getInitialNotification();

    if (remoteMessage) {
      setTimeout(() => {
        handleNotification(remoteMessage);
      }, 4000);
    }
  };

  const handleNotification = async (remoteMessage) => {
    const pageId = remoteMessage.data?.pageId;
    const campaignId = remoteMessage.data?.campaignId;
    const searchIntent = remoteMessage.data?.searchIntent;

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "push_notification_open",
      "push_notification_open",
      {
        campaignId,
      }
    );

    if (pageId) {
      if (pageId === "home") {
        navigation.navigate(APP_TARGET);
        return;
      } else if (pageId === "tipps") {
        navigation.navigate("TippsScreen");
        return;
      }
      const pageContent = getPageContent(pageId);

      setTimeout(async () => {
        if (pageContent) {
          if (pageContent.type === "scorePage") {
            navigation.navigate("ScoreScreen", {
              pageContent: pageContent,
            });
          } else if (pageContent.type === "nodePage") {
            navigation.navigate("CMSNodePageScreen", {
              pageContent: pageContent,
            });
          } else
            navigation.navigate("ResultPageScreen", {
              pageContent: pageContent,
            });
        }
      }, 250);
    }

    if (searchIntent) {
      navigation.navigate("GlobalSearchScreen", {
        searchIntent,
      });
    }
  };

  useEffect(() => {
    if (Platform.OS === "web") return;
    checkForInitialNotification(handleNotification);
    const onNotificationOpened = messaging().onNotificationOpenedApp(
      (remoteMessage) => {
        handleNotification(remoteMessage);
      }
    );

    const onMessage = messaging().onMessage(async (remoteMessage) => {
      if (!remoteMessage?.notification) return;

      if (Platform.OS !== "web")
        RNReactNativeHapticFeedback.trigger("notificationSuccess");
      showInlineNotification({
        text: (
          <Text>
            <Text style={{ fontWeight: "bold", fontSize: 15 }}>
              {remoteMessage.notification.title}
              {"\n"}
            </Text>
            {remoteMessage.notification.body}
          </Text>
        ),
        type: InlineNotificationType.NEUTRAL,
        hasIcon: false,
        duration: 3500,
        onPress: () => setTimeout(() => handleNotification(remoteMessage), 100),
      });
    });

    return () => {
      onNotificationOpened();
      onMessage();
    };
  }, [messaging]);

  return (
    <View
      style={{
        flexDirection: "row",
        flex: 1,
        ...Platform.select({
          web: { maxHeight: "100vh" as any, overflow: "hidden" },
        }),
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      {children}
    </View>
  );
};
