import { useState, useEffect } from "react";
import { getIsOffline } from "../components/search-tab/SearchResults";

export function useNetworkStatus(interval = 5000) {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const checkConnection = async () => {
      const offlineStatus = await getIsOffline();
      setIsOffline(offlineStatus);
    };

    checkConnection(); // Direkt beim Start überprüfen
    const intervalId = setInterval(checkConnection, interval); // Regelmäßig überprüfen

    return () => clearInterval(intervalId);
  }, [interval]);

  return isOffline;
}
